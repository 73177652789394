<template>
  <v-app id="inspire">
    <Alert />
    <v-content>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" md="4" sm="8">
            <v-card elevation="24">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>ඇතුල් වන්න</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    label="ඊ-මේල් ලිපිනය"
                    name="username"
                    prepend-icon="mdi-account"
                    type="text"
                    v-model="credentials.email"
                  />
                  <v-text-field
                    label="මුරපදය"
                    name="password"
                    prepend-icon="mdi-lock"
                    type="password"
                    v-model="credentials.password"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  :loading="loading"
                  @click="this.login"
                  block
                  color="primary"
                  elevation="5"
                >ඇතුල් වන්න</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import { mapMutations } from "vuex";
import { SHOW_ALERT } from "../../store/mutation-types";
import { UserService } from "../../services/user.service";
import Alert from '../Alert';

export default {
  components: {
    Alert
  },

  data: () => ({
    loading: false,
    credentials: {
      email: '',
      password: ''
    }
  }),

  methods: {
    ...mapMutations({ showAlert: SHOW_ALERT }),

    login: async function () {
      this.loading = true;
      try {
        const response = await UserService.login(this.credentials, 1); // Role = 1 (Admin)
        if (response.status == 200) {
          this.$router.push({ name: 'adminDashboard' });
        }
      } catch (error) {
        this.showAlert({ message: "ඇතුළුවීම අසාර්ථකයි", type: "error" });
      }
      this.loading = false;
    }
  }
}
</script>

<style>
</style>